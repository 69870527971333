<template>
    <div class="detail-job-box">
        <div class="box">
            <h2 class="job-title">{{detail.JobTitle}}</h2>
            <p class="job-address"><van-icon name="location" color="#ffffff" size="20px"/>{{detail.Province}} · {{detail.City}}</p>
            <div class="job-info">
                <div>
                    <label>上门时间</label>
                    <div>{{detail.ExecuteStartDtStr}}-<br>{{detail.ExecuteEndDtStr}}</div>
                </div>
                <div>
                    <label>预计服务天数</label>
                    <div>{{detail.ServiceDayCount}}</div>
                </div>
                <div>
                    <label>预算范围</label>
                    <div v-if="detail.JobType ==6&&detail.IsProjectJob == 0">￥{{detail.Amount}}</div>
                    <div v-else>￥{{detail.MinAmount}}-{{detail.MaxAmount}}</div>
                </div>
            </div>
            <div class="job-info2">
                <div>
                    <label>工单号</label>
                    <div>{{detail.JobNo}}</div>
                </div>
                <div>
                    <label>现场联系人</label>
                    <div>{{detail.ContactP}}</div>
                </div>
                <div>
                    <label>发单时间</label>
                    <div>{{detail.PubDtStr}}</div>
                </div>
            </div>
            <div class="job-info3 min-title">
                <h5>服务产品</h5>
                <div>
                    <div class="info-item3" v-for="(item, index) in treeArr" :key="index">
                        <div class="item3-label">
                            <label>产品信息（{{index}}）</label>
                            <p>数量：<span>{{item.count}}</span></p>
                        </div>
                        <div class="item3-con">{{item.str}}</div>
                    </div>
                </div>
            </div>
            <div class="min-title">
                <h5>服务信息</h5>
                <div class="job-info2">
                    <div>
                        <label>所属行业</label>
                        <div>{{detail.IndustryStr}}</div>
                    </div>
                    <div>
                        <label>所需工程师</label>
                        <div>{{detail.NeedWorkerCount}}</div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div class="openApp">
            <button @click="openAPP">打 开 APP</button>
        </div>
        <div class="wx-mask" v-if="isShowWX"></div>
    </div>
</template>
<script>
let u = navigator.userAgent;
let ua = navigator.userAgent.toLowerCase();
import {
  GetJobDetailIndex,
} from "@/common/api.js";
export default {
    data(){
        return {
            isShowWX: false,

            jobId: '',
            shareUserId: '',
            shareUserType: '',

            detail:{},
            treeArr: []
        }
    },
    created() {
        this.jobId=Number(this.$route.query.jobId);
        this.shareUserId=Number(this.$route.query.shareUserId) || 0;
        this.shareUserType=Number(this.$route.query.shareUserType) || 0;
        this.getDetailInfo();
    },
    methods: {
        async getDetailInfo() {
            let data = {}
            data.JobId = this.jobId
            let res = await GetJobDetailIndex(data)
            console.log(res)
            this.detail = res.Data
            for(let i in this.detail.ProductTrees) {
                let objTree = {}
                let a = this.detail.ProductTrees[i]
                objTree.str = `${a.ProductDomainName}/${a.ServiceStageName}/${a.ProductName}/${a.BrandName}/${a.SerialName}`
                objTree.count = a.Count
                this.treeArr.push(objTree)
            }
        },
        openAPP: function () {
            let self =this
            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                this.isShowWX = true;
                return;
            }
            if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
                console.log('安卓');
                window.location.href = `gongkongsupai://com.gongkong.supai?launchType=5&jobId=${self.jobId}&shareUserId=${self.shareUserId}&shareUserType=${self.shareUserType}`;
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    window.location.href = 'https://wx.insupai.com/download/download.html';
                    clearTimeout(this.timer);
                }, 3000);
            } else if (u.indexOf('iPhone') > -1) {
                console.log('苹果');
                window.location.href = `gongkongsupai://param?launchType=5&jobId=${self.jobId}&shareUserId=${self.shareUserId}&shareUserType=${self.shareUserType}`;
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    window.location.href = 'https://wx.insupai.com/download/download.html';
                    clearTimeout(this.timer);
                }, 3000);
            }
        },
    }
}
</script>
<style lang="scss">
    @import '~@/style/base.scss';
    .detail-job-box {
        width: 10rem;
        margin: 0 auto;
        height: 100vh;
        overflow: hidden;
        position: relative;
        background-size: contain;
        background-color: #f9f9f9;
        background-position: center px2rem(-80px);
        @include bg('~static/share/orderDetailTop.png');
        
        .box {
            padding: px2rem(60px) px2rem(28px);
            .job-title {
                color: #ffffff;
                font-weight: bold;
                font-size: px2rem(40px);
                line-height: px2rem(46px);
            }
            .job-address {
                margin-top: px2rem(42px);
                margin-bottom: px2rem(52px);
                display: flex;
                color: #ffffff;
                font-weight: 500;
                font-size: px2rem(28px);
                align-items: center;
                line-height: normal;
            }
            .job-info {
                background: #ffffff;
                min-height: px2rem(180px);
                border: 1px solid #F9F9F9;
                border-radius: px2rem(20px);
                padding: px2rem(34px) 0;
                display: flex;
                justify-content: space-around;
                line-height: normal;
                margin-bottom: px2rem(30px);
                box-shadow: 0 0 15px rgba(102,102,102,.4);
                >div {
                    flex:0 0 33%;
                    border-right: 1px solid rgba(0,0,0,.1);
                    &:last-child {
                        border-right: none;
                    }
                    &:first-child {
                        > div {
                            font-weight: 400;
                            font-size: px2rem(28px);
                        }
                    }
                    > div {
                        min-height: px2rem(60px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        line-height: normal;
                        font-size: px2rem(36px);
                        color: #333333;
                        font-weight: 500;
                    }
                }
                label {
                    display: block;
                    text-align: center;
                    color: #999999;
                    font-size: px2rem(26px);
                    line-height: px2rem(26px);
                    padding-bottom: px2rem(26px);
                }
            }
            .job-info2 {
                >div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: px2rem(70px);
                    label {
                        color: #666666;
                        font-size: px2rem(28px);
                    }
                    div {
                        font-size: px2rem(30px);
                        color: #333333;
                        font-weight: 500;
                    }
                }
            }
            .min-title {
                h5 {
                    padding-top: px2rem(40px);
                    padding-bottom: px2rem(36px);
                    font-size: px2rem(32px);
                    color: #333333;
                    font-weight: bold;
                }
            }
            .job-info3 {
                > div {
                    background: #ffffff;
                    min-height: px2rem(180px);
                    border: 1px solid #F9F9F9;
                    border-radius: px2rem(20px);
                    padding: 0 px2rem(20px);
                    box-shadow: 0 0 15px rgba(102,102,102,.4);
                    .info-item3 {
                        margin: px2rem(34px) 0;
                        .item3-label {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            label,p {
                                font-size: px2rem(28px);
                                color: #666666;
                                span {
                                    color: #333333;
                                }
                            }
                        }
                        .item3-con {
                            font-size: px2rem(28px);
                            color: #333333;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                            height: px2rem(80px);
                            line-height: px2rem(80px);
                        }
                    }
                }
            }
        }
        .openApp {
            padding: px2rem(50px) px2rem(60px);
            background: #ffffff;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            button {
                display: block;
                width: 100%;
                height: px2rem(88px);
                background: #F75959;
                border-radius: 44px;
                font-size: px2rem(32px);
                color: #ffffff;
            }
        }
    }
    .wx-mask {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        @include bg('~static/h5/invite/cover.png');
        background-size: cover;
    }
</style>